<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="예">예</b-form-radio>
              <b-form-radio value="아니오">아니오</b-form-radio>
              <b-form-radio value="모름">모름</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '예' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-checkbox-group v-model="q.answer[1].data">
              <b-form-checkbox value="당뇨병성망막병증">당뇨병성망막병증</b-form-checkbox>
              <b-form-checkbox value="당뇨병성신경병증">당뇨병성신경병증</b-form-checkbox>
              <b-form-checkbox value="당뇨병성신증">당뇨병성신증</b-form-checkbox>
              <b-form-checkbox value="심혈관질환">심혈관질환</b-form-checkbox>
              <b-form-checkbox value="당뇨발">당뇨발</b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q28',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '합병증 여부',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '합병증 여부',
        answer: [
          {
            data: '',
          },
          {
            label: '합병증 종류',
            data: [],
          },
        ],
      };
    }
    if (this.q.answer[0].data === '아니오' || this.q.answer[0].data === '모름') {
      // '아니오' 또는 '금연' 선택시 '예' 하위의 질문 답변 초기화
      while (this.q.answer[1].data.length) {
        this.q.answer[1].data.pop();
      }
    }
  },
};
</script>